<template>
  <div class="content">
    <div></div>
  </div>
</template>

<script>
export default {
  name: "Pushcard",
  data() {
    return {
      appid: "wx5ea19f19671b63b5",
      redirect_uri: "",
      state: "",
    };
  },
  created() {
    let type = this.$route.params.type;
    let pushid = this.$route.params.pushid;
    this.state = type + "-" + pushid;
    this.redirect_uri =
      `http://shang.xiaoruizhixi.com/qrcode-boot/api/test/pushactivecards/${this.state}`;
    
    window.location.href=this.redirect_uri

    // window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${
    //   this.appid
    // }&redirect_uri=${encodeURIComponent(
    //   this.redirect_uri
    // )}&response_type=code&scope=snsapi_base&state=${
    //   this.state
    // }#wechat_redirect `;
  },
};
</script>

<style scoped>
</style>